<template>
  <div>
    <el-header>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <img src="@/assets/images/logo.svg" class="nav_logo" />

        <el-menu-item index="1" @click="selectRoute('/home')"
          >解决方案</el-menu-item
        >
        <el-menu-item index="2" @click="selectRoute('/product')"
          >产品</el-menu-item
        >
        <el-menu-item index="3" @click="selectRoute('/service')"
          >服务</el-menu-item
        >
        <el-menu-item index="4" @click="selectRoute('/about')"
          >关于</el-menu-item
        >
        <div class="book_test_btn" @click="selectRoute('#footer-area')">
          预约测试
        </div>
      </el-menu>
    </el-header>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: String,
      required: true
    }
  },
  methods: {
    selectRoute (path) {
      if (path === '#footer-area') {
        document
          .getElementById('footer-area')
          .scrollIntoView({ behavior: 'smooth' })
      } else if (this.$route.path !== path) {
        this.$emit('route-selected', path)
      }
    },
    handleSelect (index) {
      this.$emit('update:activeIndex', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-header {
  position: absolute;
  top: 0;
  z-index: 22;
  width: 100%;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.1);
  .book_test_btn {
    margin-top: 14px;
  }
}
.el-menu {
  background-color: rgba(255, 255, 255, 0);
}
.el-menu.el-menu--horizontal {
  display: flex;
  justify-content: space-around;
  // padding: 0 100px;
  border-bottom: 0;
}

.el-menu--horizontal > .el-menu-item {
  font-size: 16px;
  color: #fff;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #fff;
  color: #fff;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #fff;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent;
}
.nav_logo {
  width: 105px;
  height: 40px;
  margin-top: 11px;
}
</style>
