<template>
  <div id="app">
    <NavigationBar
      :activeIndex="activeIndex"
      @route-selected="handleSelect"
      @update:activeIndex="updateActiveIndex"
    />
    <el-main>
      <router-view ref="FooterArea" />
    </el-main>
    <QrCode />
  </div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'
import QrCode from './components/QrCode.vue'
export default {
  name: 'App',
  components: {
    NavigationBar,
    QrCode
  },
  data () {
    return {
      activeIndex:
        this.$route.path.split('/')[1] === 'home'
          ? '/'
          : this.$route.path.split('/')[1]
    }
  },
  methods: {
    handleSelect (path) {
      this.$router.push({ path })
    },
    updateActiveIndex (index) {
      this.activeIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.el-main {
  padding: 0;
}
</style>
