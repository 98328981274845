import Vue from 'vue'
import App from './App.vue'
import router from './route'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import '@/assets/style/offical.css'
import '@/assets/style/safe.scss'
import '@/rem.js'
// import "@/assets/css/bootstrap.css"

Vue.use(VueRouter)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
