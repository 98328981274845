/**
 * 移动端适配的方法
 */

// import store from '@/store'
//是否移动端
// const isMobile = store.getters.isMobile
// 设置 rem 函数
function setRem() {
    // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth / 1920;
    // 设置页面根节点字体大小 电脑端锁定16px,手机端自适应
    // document.documentElement.style.fontSize = isMobile ? 16 * scale + 'px' : '16px'
    document.documentElement.style.fontSize = 16 * scale + "px";
}

export const initRem = () => {
// 调用一次，初始化rem
setRem();

const i = "orientationchange" in window ? "orientationchange" : "resize";

// 改变窗口大小时重新设置 rem,用节流防止改变窗口大小时卡死
let timer = undefined;
window.addEventListener(i, function () {
    if (timer) {
    clearTimeout(timer);
    }
    timer = window.setTimeout(() => {
    setRem();
    clearTimeout(timer);
    }, 200);
});
};

initRem();
  