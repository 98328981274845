<template>
  <div>
    <div class="message_icon">
      <div class="iconfont_box" @mouseover="showPhone" @mouseleave="hide">
        <img src="@/assets/images/icon_phone.png" />
      </div>
      <div class="iconfont_box" @mouseover="showQRCode" @mouseleave="hide">
        <img src="@/assets/images/icon_weixin.png" />
      </div>
    </div>

    <div class="phone" v-if="isPhoneVisible">
      <p>400-663-7890</p>
    </div>

    <div class="qr-code" v-if="isQRCodeVisible">
      <img src="@/assets/images/QRCode.png" alt="QR Code" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isPhoneVisible: false,
      isQRCodeVisible: false
    }
  },
  methods: {
    showPhone () {
      this.isPhoneVisible = true
      this.isQRCodeVisible = false
    },
    showQRCode () {
      this.isQRCodeVisible = true
      this.isPhoneVisible = false
    },
    hide () {
      this.isPhoneVisible = false
      this.isQRCodeVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.message_icon {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 1000;
  width: 55px;
  height: 120px;
  background-color: #fff;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: center;
  padding: 0 7px;
  cursor: pointer;
  .iconfont_box {
    padding: 10px 0;
    img {
      width: 38px;
      height: 38px;
    }
  }
  .iconfont_box:nth-child(1) {
    border-bottom: 1px solid #d3d3d3;
  }
}

.phone {
  position: fixed;
  bottom: 100px;
  right: 90px;
  z-index: 1000;
  width: 140px;
  height: 45px;
  background-color: #fff;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  font-size: 18px;
  text-align: center;
  line-height: 45px;
  transition: all 0.3s ease-in-out;
}

.qr-code {
  position: fixed;
  bottom: 40px;
  right: 90px;
  z-index: 1000;
  width: 150px;
  height: 150px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.2);
  img {
    width: 100%;
    border-radius: 10px;
  }
}
</style>
